import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { MdAdd } from "react-icons/md";
import { toast } from "react-toastify";

import CustomLink from "../../components/UI/Link";
import LoadingCM from "../../components/UI/Loading";
import styles from "./Cars.module.css";
import {
  getRelatedCars,
  getCompany,
  editCompany,
  deleteCompany,
} from "../../api/requests";
import { IMG_BASE_URL } from "../../constants/main";
import DeleteModal from "../../components/UI/DeleteModal";

function Cars() {
  const { url } = useRouteMatch();
  const params = useParams();
  const history = useHistory();
  const [cars, setCars] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({});
  const [name, setName] = useState("");
  const [changedImage, setChangedImage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handelDeleteModal = () => {
    setShowDeleteModal((state) => !state);
  };

  const getInfo = async () => {
    setIsLoading(true);
    const result = await getCompany(params.companyId);
    const res = await getRelatedCars(params.companyId);

    setIsLoading(false);

    if (!res.ok || !result.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setImage(result.data.imageUrl);
    setName(result.data.name);
    setCars(res.data);
  };

  const handelImageSubmit = async () => {
    if (!name) {
      return toast.error("لطفا همه ی فیلد ها را پر کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    setIsLoading(true);
    const res = await editCompany(params.companyId, name);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("کمپانی با موفقیت ویرایش شد", {
      position: "top-center",
      autoClose: 1500,
    });

    setTimeout(() => history.goBack(), 1500);
  };

  const handelDelete = async () => {
    setIsLoading(true);
    const res = await deleteCompany(params.companyId);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("کمپانی با موفقیت حذف شد", {
      position: "top-center",
      autoClose: 1500,
    });

    setTimeout(() => history.goBack(), 1500);
  };
  useEffect(() => {
    getInfo();
  }, []);

  const content = cars.map((item) => (
    <CustomLink normal="true" key={item._id} to={`${url}/cars/${item._id}`}>
      <div className="col-12">
        <div className={styles.row} style={{height:70}}>
          <div className={styles.item}>
            <div>
              <span>{item.name}</span>
            </div>
          </div>
        </div>
      </div>
    </CustomLink>
  ));

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <div className="main-panel">
      <div className={styles.add}>
        <div className={`row ${styles.firstRow}`}>
          <div className={styles.title}>مشخصات شرکت</div>
          <div className={`col-xl-2 ${styles.imageContainer}`}>
            {
              <label htmlFor="image" className={styles.excel}>
                {image ? (
                  <>
                    {changedImage && (
                      <img
                        src={`${URL.createObjectURL(image)}`}
                        className={styles.image}
                      />
                    )}
                    {!changedImage && (
                      <img
                        src={`${IMG_BASE_URL}/${image}`}
                        className={styles.image}
                      />
                    )}
                  </>
                ) : (
                  "محل آپلود عکس"
                )}
              </label>
            }
            <input
              id="image"
              type="file"
              style={{ visibility: "hidden" }}
              className="form-control"
              onChange={(e) => {
                if (e.target.files[0].size > 500 * 1000) {
                  return toast.error("حجم عکس باید کمتر از 500 کیلوبایت باشد", {
                    position: "top-center",
                    autoClose: 2000,
                  });
                }
                setChangedImage(true);
                setImage(e.target.files[0]);
              }}
            />
          </div>
          <div className="col-xl-4">
            <input
              type="text"
              className="form-control"
              placeholder="نام شرکت"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-xl-4" className={styles.btn}>
            <button className="btn btn-success" onClick={handelImageSubmit}>
              ویرایش
            </button>

            <button
              className="btn btn-danger col-6"
              style={{ marginRight: 5 }}
              onClick={(e) => {
                e.preventDefault();
                handelDeleteModal();
              }}
            >
              حذف
            </button>
          </div>
        </div>
      </div>

      <div className={styles.title}>لیست خودرو ها</div>
      <div className={styles.container}>{content}</div>
      <CustomLink normal="true" to={`${url}/cars/add`} className={styles.fixbtn}>
        <MdAdd size={22} />
      </CustomLink>

      <DeleteModal
        show={showDeleteModal}
        onClose={handelDeleteModal}
        onDelete={() => {
          handelDelete();
          handelDeleteModal();
        }}
      />
    </div>
  );
}

export default Cars;
