import React from "react";
import styles from "./ProgressBar.module.css";

export default function Loading(props) {
  return (
    <div className={styles.loading}>
      <label htmlFor="file"> در حال بارگذازی </label>
      <br/>
      <progress id="file" value={props.value} max="100" className={styles.progressBar}/>
    </div>
  );
}
