import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import Select from "react-select";

import LoadingCM from "../../components/UI/Loading";
import {
  editCar,
  deleteCar,
  getCarById,
  getProducts as getProductsRequest,
  getCompanies as getCompaniesRequest,
} from "../../api/requests";
import styles from "./EditCar.module.css";
import DeleteModal from "../../components/UI/DeleteModal";

function AddCar() {
  const history = useHistory();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [name, setName] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companies, setCompanies] = useState("");
  const [displacement, setDisplacement] = useState("");
  const [engineOilType, setEngineOilType] = useState("");
  const [engineOilVol, setEngineOilVol] = useState("");
  const [gearBoxOilType, setGearBoxOilType] = useState("");
  const [gearBoxOilVol, setGearBoxOilVol] = useState("");
  const [recommendedOils, setRecommendedOils] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handelDeleteModal = () => {
    setShowDeleteModal((state) => !state);
  };

  const getInfo = async () => {
    setIsLoading(true);
    const productRes = await getProductsRequest();
    const carRes = await getCarById(params.carId);
    const companyRes = await getCompaniesRequest();

    setIsLoading(false);

    if (!productRes.ok || !carRes.ok || !companyRes.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    let productObject = productRes.data.map((items) => ({
      label: items.name,
      value: items._id,
    }));

    let transformedRecommendedOils = carRes.data.recommendedOils.map(
      (items) => ({
        label: items.name,
        value: items._id,
      })
    );

    let companies = companyRes.data.map((items) => ({
      label: items.name,
      value: items._id,
    }));

    setName(carRes.data.name);
    setEngineOilVol(carRes.data.engineOilVol);
    setEngineOilType(carRes.data.engineOilType);
    setDisplacement(carRes.data.displacement);
    setGearBoxOilVol(carRes.data.gearBoxOilVol);
    setGearBoxOilType(carRes.data.gearBoxOilType);
    setRecommendedOils(transformedRecommendedOils);
    ////////////////////
    setProducts(productObject);
    ///////////////////
    setCompanies(companies);
    setSelectedCompany({
      label: carRes.data.company.name,
      value: carRes.data.company._id,
    });
  };

  const handelSubmit = async () => {
    setIsLoading(true);
    let values = {
      displacement,
      name,
      engineOilType,
      engineOilVol,
      gearBoxOilType,
      gearBoxOilVol,
    };

    if (selectedCompany.value) {
      values.company = selectedCompany.value;
    } else {
      values.company = selectedCompany;
    }

    let initialRecomendedOils = recommendedOils;
    let transformedRecommendedOils = initialRecomendedOils.map(
      (items) => items.value
    );

    values.recommendedOils = transformedRecommendedOils;
    const res = await editCar(params.carId, values);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("ماشین با موفقیت ویرایش شد", {
      position: "top-center",
      autoClose: 1500,
    });

    setTimeout(() => history.goBack(), 1500);
  };

  const handelDelete = async () => {
    setIsLoading(true);
    const res = await deleteCar(params.carId);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }

    toast.success("حذف موفقیت آمیز بود", {
      position: "top-center",
      autoClose: 1000,
    });

    setTimeout(() => history.goBack(), 1000);
  };

  useEffect(() => {
    getInfo();
  }, []);

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <div className="main-panel">
      <div className={styles.container}>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>شرکت سازنده</label>
            {companies.length > 0 && selectedCompany && (
              <Select
                options={companies}
                className={styles.select}
                placeholder="انتخاب کنید..."
                defaultValue={selectedCompany}
                onChange={(item) => {
                  setSelectedCompany(item.value);
                }}
                isLoading={isLoading}
              />
            )}
          </div>

          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>نام</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>

          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>حجم موتور</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setDisplacement(e.target.value)}
              value={displacement}
            />
          </div>

          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>نوع روغن موتور</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setEngineOilType(e.target.value)}
              value={engineOilType}
            />
          </div>

          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>حجم روغن موتور</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setEngineOilVol(e.target.value)}
              value={engineOilVol}
            />
          </div>

          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>نوع روغن گیربکس</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setGearBoxOilType(e.target.value)}
              value={gearBoxOilType}
            />
          </div>

          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>حجم روغن گیربکس</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setGearBoxOilVol(e.target.value)}
              value={gearBoxOilVol}
            />
          </div>
          <div className="col-sm-12">
            <label>انتخاب محصول پیشنهادی</label>
            {recommendedOils.length > 0 && (
              <Select
                options={products}
                isClearable={true}
                isMulti={true}
                className={styles.select}
                placeholder="انتخاب کنید..."
                defaultValue={recommendedOils}
                onChange={(item) => {
                  setRecommendedOils(item);
                }}
                isLoading={isLoading}
              />
            )}
            {recommendedOils.length < 1 && (
              <Select
                options={products}
                isClearable={true}
                isMulti={true}
                className={styles.select}
                placeholder="انتخاب کنید..."
                onChange={(item) => {
                  setRecommendedOils(item);
                }}
                isLoading={isLoading}
              />
            )}
          </div>
          <div className="col-xl-4" className={styles.btn}>
            <button className="btn btn-success" onClick={handelSubmit}>
              ویرایش
            </button>

            <button
              className="btn btn-danger col-6"
              style={{ marginRight: 5 }}
              onClick={(e) => {
                e.preventDefault();
                handelDeleteModal();
              }}
            >
              حذف
            </button>
          </div>
        </div>
      </div>

      <DeleteModal
        show={showDeleteModal}
        onClose={handelDeleteModal}
        onDelete={() => {
          handelDelete();
          handelDeleteModal();
        }}
      />
    </div>
  );
}

export default AddCar;
