import React, { Fragment, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { MdUpload } from "react-icons/md";
import { toast } from "react-toastify";

import CustomLink from "../../components/UI/Link";
import NotFound from "../../components/UI/NotFound";
import { IMG_BASE_URL } from "../../constants/main";
import LoadingCM from "../../components/UI/Loading";
import { EXCEL_LOG } from "../../constants/main";
import {
  getCompanies as getCompaniesRequest,
  addCompany,
  uploadCompaniesExcel,
} from "../../api/requests";
import Search from "../../components/UI/Search";
import styles from "./Company.module.css";
import ProgressBar from "../../components/UI/ProgressBar";

function Company() {
  const { url } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [excel, setExcel] = useState({ name: "" });
  const [excelProgress, setExcelProgress] = useState(0);
  const [image, setImage] = useState({});
  const [name, setName] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const getCompanies = async () => {
    setIsLoading(true);
    const res = await getCompaniesRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    setCompanies(res.data);
    setFilteredCompanies(res.data);
  };

  const handelExcelSubmit = async () => {
    if (!excel.name) {
      return toast.error("فایل اکسل نباید خالی باشد", {
        position: "top-center",
        autoClose: 2000,
      });
    }

    setIsUploading(true);
    const res = await uploadCompaniesExcel(excel, (excelProgress) =>
      setExcelProgress(excelProgress)
    );
    setIsUploading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("کمپانی با موفقیت افزوده شد", {
      position: "top-center",
      autoClose: 1500,
    });

    setTimeout(() => window.location.reload(), 1500);
  };

  const handelImageSubmit = async () => {
    if (!name || !image.name) {
      return toast.error("لطفا همه ی فیلد ها را پر کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    setIsLoading(true);
    let values = { name, image };
    const res = await addCompany(values);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("کمپانی با موفقیت افزوده شد", {
      position: "top-center",
      autoClose: 1500,
    });

    setTimeout(() => window.location.reload(), 1500);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const handelSearchInput = (value) => {
    if (value.trim() === "") return setFilteredCompanies(companies);

    const filteredItems = companies.filter((item) => item.name.match(value));
    setFilteredCompanies(filteredItems);
  };

  const content = filteredCompanies.map((item) => (
    <CustomLink normal key={item._id} to={`${url}/${item._id}`}>
      <div className="col-12">
        <div className={styles.row}>
          <div className={styles.item}>
            <div>
              <span>
                <img src={`${IMG_BASE_URL}/${item.imageUrl}`} alt="" />
              </span>
              <span>{item.name}</span>
            </div>
          </div>
        </div>
      </div>
    </CustomLink>
  ));

  if (isUploading) return <ProgressBar value={excelProgress} />;

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <Fragment>
      <div className={styles.mainpanel}>
        <div className={styles.add}>
          <a href={EXCEL_LOG} className={styles.log} target="_blank">
            مشاهده صفحه لاگ
          </a>
          <div className="col-xl-6">
            <label htmlFor="excel" className={styles.excel}>
              <MdUpload size={22} />{" "}
              {excel.name ? excel.name : "محل آپلود فایل"}
            </label>
            <input
              id="excel"
              style={{ visibility: "hidden" }}
              type="file"
              placeholder="ارسال"
              className="form-control"
              onChange={(e) => {
                if (e.target.files[0].size > 500 * 1000) {
                  return toast.error(
                    "حجم فایل باید کمتر از 500 کیلوبایت باشد",
                    {
                      position: "top-center",
                      autoClose: 2000,
                    }
                  );
                }
                setExcel(e.target.files[0]);
              }}
            />
            <div className="col-xl-4" className={styles.btn}>
              <button className="btn btn-success" onClick={handelExcelSubmit}>
                ثبت
              </button>
            </div>
          </div>

          <hr />
          <div className={`row ${styles.secondRow}`}>
            <div className={styles.title}>افزودن شرکت</div>

            <div className="col-xl-2">
              {
                <label htmlFor="image" className={styles.excel}>
                  <MdUpload size={22} />{" "}
                  {image.name ? (
                    <img
                      src={`${URL.createObjectURL(image)}`}
                      className={styles.image}
                    />
                  ) : (
                    "محل آپلود عکس"
                  )}
                </label>
              }
              <input
                id="image"
                type="file"
                style={{ display: "none" }}
                className="form-control"
                onChange={(e) => {
                  if (e.target.files[0].size > 500 * 1000) {
                    return toast.error(
                      "حجم عکس باید کمتر از 500 کیلوبایت باشد",
                      {
                        position: "top-center",
                        autoClose: 2000,
                      }
                    );
                  }
                  setImage(e.target.files[0]);
                }}
              />
            </div>
            <div className="col-xl-4">
              <input
                type="text"
                className="form-control"
                placeholder="نام شرکت"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-xl-4" className={styles.btn}>
              <button className="btn btn-success" onClick={handelImageSubmit}>
                ثبت
              </button>
            </div>
          </div>
        </div>
      </div>

      <Search
        onInput={(val) => handelSearchInput(val)}
        style={{ padding: "0px" }}
        company
      />
      <div className="main-panel">
        {filteredCompanies.length < 1 ? (
          <NotFound title="محصولی یافت نشد" />
        ) : (
          <Fragment>
            <div className={styles.title}>لیست شرکت ها</div>
            <div className={styles.container}>{content}</div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default Company;
