import React, { useEffect, useState } from "react";
import Editor, { buttonList } from "suneditor-react";
import Select from "react-select";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

import styles from "./EditProduct.module.css";
import { CATEGORIES, IMG_BASE_URL } from "../../constants/main";
import LoadingCM from "../../components/UI/Loading";
import {
  editProduct,
  getProductById as getProductRequest,
  deleteProduct as deleteProductRequest,
} from "../../api/requests";
import DeleteModal from "../../components/UI/DeleteModal";

function AddProduct() {
  const history = useHistory();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState({});
  const [changedImage, setChangedImage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handelDeleteModal = () => {
    setShowDeleteModal((state) => !state);
  };

  const deleteProduct = async () => {
    setIsLoading(true);
    const res = await deleteProductRequest(params.productId);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }

    toast.success("حذف موفقیت آمیز بود", {
      position: "top-center",
      autoClose: 1000,
    });

    setTimeout(() => history.goBack(), 1000);
  };

  const convertCategories = () => {
    let convertedOptions = [];
    for (let key in CATEGORIES) {
      convertedOptions.push({ label: CATEGORIES[key], value: key });
    }
    setCategories(convertedOptions);
  };

  const getProduct = async () => {
    setIsLoading(true);
    const res = await getProductRequest(params.productId);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }

    let product = res.data;

    setCategory({
      label: CATEGORIES[product.category],
      value: product.category,
    });
    setContent(product.content);
    setDescription(product.description);
    setImage(product.imageUrl);
    setPrice(product.price);
    setName(product.name);
  };

  const handelImageSubmit = async () => {
    if (!name || !category || !description || !content || !price) {
      return toast.error("لطفا همه ی فیلد ها را پر کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    setIsLoading(true);
    let values = {
      name,
      category: category.value,
      description,
      content,
      price,
    };
    if (changedImage) {
      values.image = image;
    } else {
      values.imageUrl = image;
    }

    const res = await editProduct(params.productId, values);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("محصول با موفقیت افزوده شد", {
      position: "top-center",
      autoClose: 1500,
    });

    setTimeout(() => history.goBack(), 1500);
  };

  useEffect(() => {
    getProduct();
    convertCategories();
  }, []);

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <div className="main-panel">
      <div className={styles.add}>
        <div className="row">
          <div className={styles.title}>مشخصات محصول</div>
          <div className="col-xl-6">
            {
              <label htmlFor="image" className={styles.excel}>
                {image ? (
                  <>
                    {changedImage && (
                      <img
                        src={`${URL.createObjectURL(image)}`}
                        className={styles.image}
                      />
                    )}
                    {!changedImage && (
                      <img
                        src={`${IMG_BASE_URL}/${image}`}
                        className={styles.image}
                      />
                    )}
                  </>
                ) : (
                  "محل آپلود عکس"
                )}
              </label>
            }
            <input
              id="image"
              type="file"
              style={{ visibility: "hidden" }}
              className="form-control"
              onChange={(e) => {
                if (e.target.files[0].size > 500 * 1000) {
                  return toast.error("حجم عکس باید کمتر از 500 کیلوبایت باشد", {
                    position: "top-center",
                    autoClose: 2000,
                  });
                }
                setChangedImage(true);
                setImage(e.target.files[0]);
              }}
            />
          </div>

          <br />

          <div className="col-xl-6">
            <label>دسته</label>
            <Select
              options={categories}
              className={styles.select}
              placeholder="انتخاب کنید..."
              value={category}
              onChange={(item) => {
                setCategory(item);
              }}
            />
          </div>

          <div className="col-xl-6">
            <label>نام</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="col-xl-6">
            <label>قیمت</label>
            <input
              className="form-control"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>

          <div className="col-xl-12">
            <label>توضیحات</label>
            <textarea
              name=""
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="col-xl-12">
            <label>متن</label>
            {content && (
              <Editor
                enableToolbar={true}
                onChange={(text) => setContent(text)}
                defaultValue={content}
                setDefaultStyle="font-family: IranSans; height: 40vh;"
                setOptions={{
                  buttonList: buttonList.complex,
                  font: [
                    "IranSans",
                    "IranSansBold",
                    "Arial",
                    "Impact",
                    "Tahoma",
                  ],
                }}
              />
            )}
            {!content && (
              <Editor
                enableToolbar={true}
                onChange={(text) => setContent(text)}
                setDefaultStyle="font-family: IranSans; height: 40vh;"
                setOptions={{
                  buttonList: buttonList.complex,
                  font: [
                    "IranSans",
                    "IranSansBold",
                    "Arial",
                    "Impact",
                    "Tahoma",
                  ],
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="col-8" className={styles.btn}>
        <button
          className="btn btn-success col-6"
          type="submit"
          onClick={handelImageSubmit}
        >
          ویرایش
        </button>

        <button
          className="btn btn-danger col-6"
          style={{ marginRight: 5 }}
          onClick={(e) => {
            e.preventDefault();
            handelDeleteModal();
          }}
        >
          حذف
        </button>
      </div>

      <DeleteModal
        show={showDeleteModal}
        onClose={handelDeleteModal}
        onDelete={() => {
          deleteProduct();
          handelDeleteModal();
        }}
      />
    </div>
  );
}

export default AddProduct;
