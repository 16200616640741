import React, { useState, useContext } from "react";
import logo from "../assets/img/logo-512.png";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import styles from "./Login.module.css";
import { login as loginRequest } from "../api/requests";
import LoadingCM from "../components/UI/Loading";
import AuthContext from "../store/auth-context";

export default function Login() {
  const history = useHistory();
  const authCTX = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async () => {
    setIsLoading(true);
    const res = await loginRequest({ email, password });
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("نام کاربری یا رمز عبور اشتباه است", {
        position: "top-center",
        autoClose:2000
      });
    }

    authCTX.login(res.data.token);
    history.replace('/users')
  };

  if (isLoading) return <LoadingCM type="bubbles" />;

  return (
    <div className={styles.container}>
      <div className={`${styles.row1} col-lg-6 col-md-8 col-sm-8`}>
        <img src={logo} alt="logo" className={styles.logo} />
        <input
          type="text"
          name="extraCost"
          className="form-control col-6"
          placeholder="نام کابری"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
        />
        <input
          type="text"
          name="extraCost"
          className="form-control col-8"
          placeholder="رمز عبور"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
        />

        <button className={styles.btnContainer} onClick={handleSubmit}>
          <span>ورود</span>
        </button>
      </div>
    </div>
  );
}
