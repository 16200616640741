import React, { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Formik } from "formik";

import styles from "./UserPanel.module.css";
import LoadingCM from "../../components/UI/Loading";
import {
  getUserInfo as userInfoRequest,
  editUser as editUserRequest,
} from "../../api/requests";
import ProfileCarCard from "../../components/user/ProfileCarCard";
import CustomLink from "../../components/UI/Link";
import { convertNum, editUserValidator } from "../../helper/validation";

function UserPanel() {
  const params = useParams();
  const [userInfo, setUserInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUserInfo = async () => {
    setIsLoading(true);
    const res = await userInfoRequest(params._Id);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setUserInfo(res.data);
  };

  const handleSubmit = async (value, setSubmiting) => {
    delete value.cid;
    setIsLoading(true);
    value.phoneNumber = convertNum(value.phoneNumber);
    const res = await editUserRequest(params._Id, value);
    setIsLoading(false);
    setSubmiting(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2500,
      });
    }

    toast.success("عملیات با موفقیت انجام شد", {
      position: "top-center",
      autoClose: 2500,
    });

    getUserInfo();
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const content = userInfo.cars?.map((item) => (
    <CustomLink normal key={item._id} to={`/users/${params._Id}/${item._id}`}>
      <ProfileCarCard item={item} />
    </CustomLink>
  ));

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <div className="main-panel">
      <Formik
        enableReinitialize={true}
        initialValues={{
          phoneNumber: userInfo.phoneNumber ? userInfo.phoneNumber : "",
          cid: userInfo.cid ? userInfo.cid : "",
          name: userInfo.name ? userInfo.name : "",
        }}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(values, setSubmitting)
        }
        validationSchema={editUserValidator}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldTouched,
        }) => (
          <form className={styles.add} onSubmit={handleSubmit}>
            <div className="row">
              <div className={styles.title}>مشخصات</div>
              <div className="col-xl-4">
                <label>cid</label>
                <input
                  type="text"
                  name="cid"
                  className="form-control"
                  value={values.cid}
                  readOnly
                />
              </div>

              <div className="col-xl-4">
                <label>نام</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  onChange={handleChange}
                  value={values.name}
                  onBlur={() => {
                    setFieldTouched("name");
                  }}
                />
                <span className="inputErrors">
                  {touched.name && errors.name}
                </span>
              </div>

              <div className="col-xl-4">
                <label>تلفن همراه</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  onChange={handleChange}
                  className="form-control"
                  value={values.phoneNumber}
                  onBlur={() => {
                    setFieldTouched("phoneNumber");
                  }}
                />
                <span className="inputErrors">
                  {touched.phoneNumber && errors.phoneNumber}
                </span>
              </div>

              <div className="col-xl-4" className={styles.btn}>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={isSubmitting}
                >
                  ویرایش
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <div className={styles.add}>
        <div className={styles.title}>خودرو ها</div>
        <div className={styles.container}>{content}</div>
      </div>
    </div>
  );
}

export default UserPanel;
