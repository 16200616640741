import React, { useEffect, useState, useRef } from "react";
import Editor, { buttonList } from "suneditor-react";
import Select from "react-select";
import { useHistory } from "react-router";
import { MdUpload } from "react-icons/md";
import { toast } from "react-toastify";

import styles from "./AddProduct.module.css";
import { CATEGORIES, EXCEL_LOG } from "../../constants/main";
import LoadingCM from "../../components/UI/Loading";
import { addProduct, uploadProductExcel } from "../../api/requests";
import ProgressBar from "../../components/UI/ProgressBar";

function AddProduct() {
  const history = useHistory();
  const imgRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [price, setPrice] = useState("");
  const [excel, setExcel] = useState({ name: "" });
  const [excelProgress, setExcelProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [image, setImage] = useState({});

  const convertCategories = () => {
    let convertedOptions = [];
    for (let key in CATEGORIES) {
      convertedOptions.push({ label: CATEGORIES[key], value: key });
    }
    setCategories(convertedOptions);
  };

  const handelExcelSubmit = async () => {
    if (!excel.name) {
      return toast.error("فایل اکسل نباید خالی باشد", {
        position: "top-center",
        autoClose: 2000,
      });
    }

    setIsUploading(true);
    const res = await uploadProductExcel(excel, (excelProgress) =>
      setExcelProgress(excelProgress)
    );
    setIsUploading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("محصول با موفقیت افزوده شد", {
      position: "top-center",
      autoClose: 1500,
    });

    setTimeout(() => history.goBack(), 1500);
  };

  const handelImageSubmit = async () => {
    if (
      !name ||
      !category ||
      !description ||
      !content ||
      !price ||
      !image.name
    ) {
      return toast.error("لطفا همه ی فیلد ها را پر کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    setIsLoading(true);
    let values = { name, category, description, content, price, image };
    const res = await addProduct(values);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("محصول با موفقیت افزوده شد", {
      position: "top-center",
      autoClose: 1500,
    });

    setTimeout(() => history.goBack(), 1500);
  };

  useEffect(() => {
    convertCategories();
  }, []);

  if (isLoading) return <LoadingCM type="bars" />;

  if (isUploading) return <ProgressBar value={excelProgress} />;

  return (
    <div className="main-panel">
      <div className={styles.add}>
        <a href={EXCEL_LOG} className={styles.log} target="_blank">مشاهده صفحه لاگ</a>
        <div className="col-xl-6">
          <label htmlFor="excel" className={styles.excel}>
            <MdUpload size={22} /> {excel.name ? excel.name : "محل آپلود فایل"}
          </label>
          <input
            id="excel"
            style={{ visibility: "hidden" }}
            type="file"
            placeholder="ارسال"
            className="form-control"
            onChange={(e) => {
              if (e.target.files[0].size > 500 * 1000) {
                return toast.error("حجم فایل باید کمتر از 500 کیلوبایت باشد", {
                  position: "top-center",
                  autoClose: 2000,
                });
              }
              setExcel(e.target.files[0]);
            }}
          />
          <div className="col-xl-4" className={styles.btn}>
            <button className="btn btn-success" onClick={handelExcelSubmit}>
              ثبت
            </button>
          </div>
        </div>

        <hr />

        <div className="row">
          <div className={styles.title}>مشخصات محصول</div>
          <div className="col-xl-6">
            {
              <label htmlFor="image" className={styles.excel}>
                <MdUpload size={22} />{" "}
                {image.name ? (
                  <img
                    src={`${URL.createObjectURL(image)}`}
                    className={styles.image}
                  />
                ) : (
                  "محل آپلود عکس"
                )}
              </label>
            }
            <input
              id="image"
              type="file"
              style={{ visibility: "hidden" }}
              className="form-control"
              ref={imgRef}
              onChange={(e) => {
                if (e.target.files[0].size > 500 * 1000) {
                  return toast.error("حجم عکس باید کمتر از 500 کیلوبایت باشد", {
                    position: "top-center",
                    autoClose: 2000,
                  });
                }
                setImage(e.target.files[0]);
              }}
            />
          </div>

          <br />

          <div className="col-xl-6">
            <label>دسته</label>
            <Select
              options={categories}
              className={styles.select}
              placeholder="انتخاب کنید..."
              onChange={(item) => {
                setCategory(item.value);
              }}
            />
          </div>

          <div className="col-xl-6">
            <label>نام</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="col-xl-6">
            <label>قیمت</label>
            <input
              className="form-control"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>

          <div className="col-xl-12">
            <label>توضیحات</label>
            <textarea
              name=""
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="col-xl-12">
            <label>متن</label>
            <Editor
              enableToolbar={true}
              onChange={(text) => setContent(text)}
              setDefaultStyle="font-family: IranSans; height: 40vh;"
              setOptions={{
                buttonList: buttonList.complex,
                font: ["IranSans", "IranSansBold", "Arial", "Impact", "Tahoma"],
              }}
            />
          </div>
        </div>
      </div>

      <div className="col-xl-4" className={styles.btn}>
        <button className="btn btn-success" onClick={handelImageSubmit}>
          ثبت
        </button>
      </div>
    </div>
  );
}

export default AddProduct;
