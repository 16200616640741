import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import Select from "react-select";

import LoadingCM from "../../components/UI/Loading";
import {
  addCar,
  getProducts as getProductsRequest,
} from "../../api/requests";
import styles from "./AddCar.module.css";

function AddCar() {
  const history = useHistory();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [name, setName] = useState([]);
  const [displacement, setDisplacement] = useState("");
  const [engineOilType, setEngineOilType] = useState("");
  const [engineOilVol, setEngineOilVol] = useState("");
  const [gearBoxOilType, setGearBoxOilType] = useState("");
  const [gearBoxOilVol, setGearBoxOilVol] = useState("");
  const [recommendedOils, setRecommendedOils] = useState([]);

  const getProducts = async () => {
    setIsLoading(true);
    const res = await getProductsRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    let productObject = res.data.map((items) => ({
      label: items.name,
      value: items._id,
    }));

    setProducts(productObject);
  };

  const handelSubmit = async () => {
    setIsLoading(true);
    let values = {
      displacement,
      name,
      engineOilType,
      engineOilVol,
      gearBoxOilType,
      gearBoxOilVol,
      company: params.companyId,
    };
    let initialRecomendedOils = recommendedOils;
    let transformedRecommendedOils = initialRecomendedOils.map(
      (items) => items.value
    );
    values.recommendedOils = transformedRecommendedOils;
    const res = await addCar(values);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("ماشین با موفقیت افزوده شد", {
      position: "top-center",
      autoClose: 1500,
    });

    setTimeout(() => history.goBack(), 1500);
  };

  useEffect(() => {
    getProducts();
  }, []);

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <div className="main-panel">
      <div className={styles.container}>
        <div className="row" style={{ width: "100%" }}>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>نام</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>حجم موتور</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setDisplacement(e.target.value)}
            />
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>نوع روغن موتور</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setEngineOilType(e.target.value)}
            />
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>حجم روغن موتور</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setEngineOilVol(e.target.value)}
            />
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>نوع روغن گیربکس</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setGearBoxOilType(e.target.value)}
            />
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
            <label>حجم روغن گیربکس</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setGearBoxOilVol(e.target.value)}
            />
          </div>
          <div className="col-sm-12">
            <label>انتخاب محصول پیشنهادی</label>
            <Select
              options={products}
              isClearable={true}
              isMulti={true}
              className={styles.select}
              placeholder="انتخاب کنید..."
              onChange={(item) => {
                setRecommendedOils(item);
              }}
              isLoading={isLoading}
            />
          </div>
          <div className="col-xl-4" className={styles.btn}>
            <button className="btn btn-success" onClick={handelSubmit}>
              ثبت
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCar;
