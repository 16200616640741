import React from "react";
import { MdSearch } from "react-icons/md";

import styles from "./Search.module.css";

export default function Search(props) {
  return (
    <div
      className="row"
      className={`
      ${styles.container} 
      ${props.company ? styles.companyContainer : ""}
      `}
    >
      <div className={`col-lg-6 col-md-12 ${styles.inputContainer}`}>
        <input
          className={`form-control ${styles.searchInput}`}
          placeholder="جستجو کنید..."
          onChange={(e) => props.onInput(e.target.value)}
        />
        <MdSearch className={styles.searchIcon} size={20} />
      </div>
    </div>
  );
}
