import client from './config';

//login
const loginUrl = '/auth/admin';
export const login = (values) => client.post(loginUrl, values);

//users
const usersUrl = '/user';
const userCar = '/user/car';
export const getAllUser = () => client.get(usersUrl);
export const getUser = id => client.get(usersUrl + id);
export const editUser = (id, values) => client.patch(usersUrl + "/" + id, values)
export const getUserInfo = (id) => client.get(usersUrl + "/" + id);
export const deleteUserCar = id => client.delete(userCar + "/" + id);
export const addUserCar = id => client.post(userCar + "/" + id);

//related cars to specefic company
const carUrl = '/car';
export const getRelatedCars = id => client.get(`${carUrl}?company=${id}`)
export const getCarById = id => client.get(`${carUrl}/${id}`)
export const deleteCar = id => client.delete(`${carUrl}/${id}`)
export const addCar = values => client.post(carUrl, values)
export const editCar = (id, values) => client.patch(carUrl + "/" + id, values)

//companies
const companiesUrl = '/company';
export const getCompanies = () => client.get(companiesUrl);
export const getCompany = id => client.get(companiesUrl + "/" + id);
export const addCompany = values => {
    var formData = new FormData()
    if (values.image) {
        formData.append('image', values.image, values.image.name)
    }
    formData.append('name', values.name)

    return client.post(companiesUrl, formData);
}
export const editCompany = (id, name) => {
    var formData = new FormData()

    formData.append('name', name)

    return client.patch(companiesUrl + "/" + id, formData);
}
export const uploadCompaniesExcel = (file, onUploadProgress) => {
    let formData = new FormData()
    formData.append('file', file, file.name)

    return client.post(carUrl + "/upload", formData, {
        onUploadProgress: (progress) =>
            onUploadProgress(Math.round(progress.loaded / progress.total) * 100)
    });

}
export const deleteCompany = id => client.delete(companiesUrl + "/" + id)

//news 
const newsUrl = '/post'
export const getAllNews = () => client.get(newsUrl);
export const getNewsById = id => client.get(newsUrl + "/" + id);
export const deleteNews = id => client.delete(newsUrl + "/" + id);
export const addNews = values => {
    let formData = new FormData();

    formData.append('title', values.title)
    formData.append('content', values.content)
    formData.append('image', values.image)

    return client.post(newsUrl, formData);

}
export const editNews = (id, values) => {
    let formData = new FormData();

    if (values.image.name) {
        formData.append('image', values.image, values.image.name)
    }
    formData.append('title', values.title)
    formData.append('content', values.content)

    return client.patch(newsUrl + "/" + id, formData);

}

//about-us and contact-us
const configUrl = '/config';
export const getConfig = () => client.get(configUrl);
export const setConfig = (values) => client.post(configUrl, values);

//club
const clubUrl = '/club'
export const getClubInfo = () => client.get(clubUrl + "/users");
export const setClubMember = (id, value) => client.put(clubUrl + "/" + id, { state: value });

//history
const hitsoryUrl = '/history'
export const getUserHistories = (userId, carId) => client.get(`${hitsoryUrl}/?userId=${userId}&carId=${carId}`);
export const getUserHistoryById = (id) => client.get(hitsoryUrl + "/" + id);
export const editInvoice = (id, values) => client.patch(hitsoryUrl + "/" + id, values)
export const deleteInvoice = (id) => client.delete(hitsoryUrl + "/" + id);
export const addInvoice = (values) => client.post(hitsoryUrl, values);

//products
const productUrl = '/product'
// export const getProductByCategory = (category) => client.get(`${productUrl}/?category=${category}`);
export const getAllProductByGroup = () => client.get(`${productUrl}/?category=group`);
export const getProducts = () => client.get(productUrl)
export const getProductById = (id) => client.get(productUrl + "/" + id);
export const deleteProduct = (id) => client.delete(productUrl + "/" + id);

export const addProduct = (values) => {
    let formData = new FormData();

    let description = (values.description ? values.description : ' ')
    let content = (values.content ? values.content : ' ')
    let price = (values.price <= 0 ? '0' : values.price)

    formData.append('name', values.name)
    formData.append('description', description)
    formData.append('content', content)
    formData.append('price', price)
    formData.append('category', values.category)
    formData.append('image', values.image)

    return client.post(productUrl, formData);
};
export const editProduct = (id, values) => {
    let formData = new FormData();

    let description = (values.description ? values.description : ' ')
    let content = (values.content ? values.content : ' ')
    let price = (values.price <= 0 ? '0' : values.price)

    if (values.imageUrl) {
        formData.append('imageUrl', values.imageUrl)
    }
    else {
        formData.append('image', values.image, values.image.name)
    }
    formData.append('name', values.name)
    formData.append('description', description)
    formData.append('content', content)
    formData.append('price', price)
    formData.append('category', values.category)


    return client.patch(productUrl + "/" + id, formData);
};
export const uploadProductExcel = (values, onUploadprogress) => {
    let formData = new FormData();

    formData.append('file', values, values.name)
    return client.post(`${productUrl}/upload`, formData, {
        onUploadProgress: (progress) =>
            onUploadprogress(Math.round(progress.loaded / progress.total) * 100)
    });
};

//sms
const smsUrl = '/sms';
export const sendSms = (values) => {
    if (values.company) {
        return client.post(`${smsUrl}/filter?company=${values.company}`, values.message)
    }
    else {
        return client.post(smsUrl, values.message)
    }
}
