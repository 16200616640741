import React from "react";

import styles from "./HistoryCard.module.css";

export default function HistoryCard(props) {

  return (
    <div className="col-12">
      <div className="row">
        <div className="col-6">
          <div className={styles.item}>
            <div>
              <span>تاریخ</span>
              <span>{props.item?.serviceDate?.description}</span>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className={styles.item}>
            <div>
              <span>قیمت</span>
              <span>{props.item?.totalPrice}</span>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className={styles.item}>
            <div>
              <span>کیلومتر فعلی</span>
              <span>{props.item?.currentKM}</span>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className={styles.item}>
            <div>
              <span>کیلومتر بعدی</span>
              <span>{props.item.nextKM}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <button className={styles.moreDetailBtn}>مشاهده</button>
      </div>
    </div>
  );
}
