import React, { Fragment, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { toast } from "react-toastify";
import { MdAdd } from "react-icons/md";

import CustomLink from "../../components/UI/Link";
import NotFound from "../../components/UI/NotFound";
import { IMG_BASE_URL } from "../../constants/main";
import LoadingCM from "../../components/UI/Loading";
import { getProducts as getProductsRequest } from "../../api/requests";
import Search from "../../components/UI/Search";
import styles from "./Product.module.css";

function Product() {
  const { url } = useRouteMatch();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);

  const getProducts = async () => {
    setIsLoading(true);
    const res = await getProductsRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setProducts(res.data);
    setFilteredProduct(res.data);
  };

  useEffect(() => {
    getProducts();
  }, []);

  const handelSearchInput = (value) => {
    if (value.trim() === "") return setFilteredProduct(products);

    const filteredItems = products.filter((item) => item.name.match(value));
    setFilteredProduct(filteredItems);
  };

  const content = filteredProduct.map((item) => (
    <CustomLink normal key={item._id} to={`${url}/${item._id}`}>
      <div className="col-12">
        <div className={styles.row}>
          <div className={styles.item}>
            <div>
              <span>
                <img src={`${IMG_BASE_URL}/${item.imageUrl}`} alt="" />
              </span>
              <span>{item.name}</span>
            </div>
          </div>
        </div>
      </div>
    </CustomLink>
  ));

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <Fragment>
      <Search onInput={(val) => handelSearchInput(val)} />
      {filteredProduct.length < 1 ? (
        <NotFound title="محصولی یافت نشد" />
      ) : (
        <div className="main-panel">
          <div className={styles.container}>{content}</div>
          <CustomLink
            normal="true"
            to="/products/add"
            className={styles.fixbtn}
          >
            <MdAdd size={24} />
          </CustomLink>
        </div>
      )}
    </Fragment>
  );
}

export default Product;
