import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

import NotFound from "../../components/UI/NotFound";
import LoadingCM from "../../components/UI/Loading";
import {
  getClubInfo as getUserRequest,
  setClubMember,
} from "../../api/requests";
import Search from "../../components/UI/Search";
import ClubCard from "../../components/ClubCard";

import styles from "./Club.module.css";

function Club() {
  const [users, setuser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredUser, setFiltereduser] = useState([]);

  const getUser = async () => {
    setIsLoading(true);
    const res = await getUserRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setuser(res.data);
    setFiltereduser(res.data);
  };

  const setMember = async (id, userState) => {
    setIsLoading(true);
    const res = await setClubMember(id, !userState);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }

    toast.success("عملیات با موفقیت انجام شد", {
      position: "top-center",
      autoClose: 2000,
    });

    getUser();
  };
  useEffect(() => {
    getUser();
  }, []);

  const handelSearchInput = (value) => {
    if (value.trim() === "") return setFiltereduser(users);

    const filteredItems = users.filter((item) =>
      item.userInfo[0]?.name?.match(value)
    );
    setFiltereduser(filteredItems);
  };

  const content = filteredUser.map((item, index) => (
    <div className={styles.row} key={`user+${index}`}>
      <ClubCard
        item={item}
        index={index}
        onSetMember={(id, userState) => setMember(id, userState)}
      />
    </div>
  ));

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <Fragment>
      <Search onInput={(val) => handelSearchInput(val)} />
      {filteredUser.length < 1 ? (
        <NotFound title="کاربری یافت نشد" />
      ) : (
        <div className="main-panel">{content}</div>
      )}
    </Fragment>
  );
}

export default Club;
