import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { DatePicker } from "jalali-react-datepicker";
import { toast } from "react-toastify";
import { Formik } from "formik";
import SelectBox from "react-select";

import styles from "./InVoice.module.css";
import LoadingCM from "../../components/UI/Loading";
import {
  getUserHistoryById,
  getAllProductByGroup,
  editInvoice,
  deleteInvoice as deleteInvoiceRequest,
} from "../../api/requests";
import DeleteModal from "../../components/UI/DeleteModal";

export default function InVoice() {
  const formRef = useRef();
  const location = useHistory();
  const params = useParams();
  const [history, setHistory] = useState({});
  const [products, setProducts] = useState({
    engineOil: [],
    oilFilter: [],
    gearOil: [],
    cabinFilter: [],
    airFilter: [],
    fuelFilter: [],
    hydraulicOil: [],
    accessories: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [extraCost, setExtraCost] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handelDeleteModal = () => {
    setShowDeleteModal((state) => !state);
  };

  const calcTotalPrice = (extraCostValue = "0") => {
    let changelog = formRef.current.values.changelog;

    if (
      !changelog?.engineOil &&
      !changelog.gearOil &&
      !changelog.oilFilter &&
      !changelog.cabinFilter &&
      !changelog.airFilter &&
      !changelog.hydraulicOil &&
      !changelog.fuelFilter &&
      !changelog.accessories.length > 0
    ) {
      window.location.reload();
    }

    var sum = 0;
    for (let key in changelog) {
      if (key == "accessories") {
        if (changelog[key].length) {
          for (let index in changelog[key]) {
            sum += eval(changelog[key][index].price);
          }
        }
      } else if (changelog[key]) {
        sum += eval(changelog[key].price);
      }
    }
    console.log(extraCostValue);
    sum += +extraCostValue;

    setTotalPrice(sum);
  };

  const getInVoice = async () => {
    setIsLoading(true);
    const res = await getUserHistoryById(params.invoiceId);
    const result = await getAllProductByGroup();

    if (!res.ok || !result.ok) {
      setIsLoading(false);
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    let productObject = {};
    for (let value of result.data) {
      productObject[value.category] = value.products.map((items) => ({
        label: items.name,
        value: items._id,
        price: items.price,
      }));
    }

    setIsLoading(false);
    setProducts(productObject);
    setHistory(res.data);
    setTotalPrice(res.data.totalPrice);
    setExtraCost(res.data.extraCost);
  };

  const defaultHandler = (value, accessories = false) => {
    if (accessories) {
      if (value && value.length) {
        return value.map((items) => ({
          label: items.name,
          value: items._id,
          price: items.price,
        }));
      } else {
        return null;
      }
    } else {
      if (value) {
        return [{ label: value.name, value: value._id, price: value.price }];
      } else {
        return [{ label: "انتخاب کنید...", value: "" }];
      }
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    let changelog = values.changelog;
    values.extraCost = +extraCost;

    for (let key in changelog) {
      if (key == "accessories") {
        if (changelog[key].length) {
          for (let index in values.changelog[key]) {
            if (changelog[key][index]._id) {
              values.changelog[key][index] = changelog[key][index]._id;
            } else {
              values.changelog[key][index] = changelog[key][index].value;
            }
          }
        } else {
          changelog[key] = null;
        }
      } else if (changelog[key]) {
        if (changelog[key]._id) {
          values.changelog[key] = changelog[key]._id;
        } else {
          values.changelog[key] = changelog[key].value;
        }
      }
    }

    if (values.serviceDate instanceof Date) {
      values.serviceDate = new Date(values.serviceDate).valueOf();
    } else {
      values.serviceDate = values.serviceDate.value;
    }

    const res = await editInvoice(values.id, values);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }

    toast.success("ویرایش موفقیت آمیز بود", {
      position: "top-center",
      autoClose: 2000,
    });
    setTimeout(() => location.goBack(), 2000);
  };

  const deleteInvoice = async () => {
    setIsLoading(true);
    const res = await deleteInvoiceRequest(history._id);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }

    toast.success("حذف موفقیت آمیز بود", {
      position: "top-center",
      autoClose: 1500,
    });
    setTimeout(() => location.goBack(), 1500);
  };
  useEffect(() => {
    getInVoice(products);
  }, []);

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <div className="main-panel">
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        initialValues={{
          id: history._id,
          user: params.userId ? params.userId : "",
          car: params.carId ? params.carId : "",
          serviceDate: history.serviceDate ? history.serviceDate : {},
          currentKM: history.currentKM ? history.currentKM : "",
          nextKM: history.nextKM ? history.nextKM : "",
          description: history.description ? history.description : "",
          extraCost: history.extraCost ? history.extraCost : 0,
          totalPrice: history.totalPrice ? history.totalPrice : "",
          changelog: history.changelog ? history.changelog : {},
        }}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(values, setSubmitting)
        }
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.add}>
              <div className="row">
                <div className={styles.title}>مشخصات</div>

                <div className="col-xl-4">
                  <div className={styles.inputdate}>
                    <label>تاریخ</label>
                    {values.serviceDate?.value && (
                      <DatePicker
                        onClickSubmitButton={(e) =>
                          (values.serviceDate = e.value._d)
                        }
                        className="form-control"
                        timePicker={false}
                        name="serviceDate"
                        value={values.serviceDate.value}
                      />
                    )}
                  </div>
                  <span className="inputErrors">
                    {touched.name && errors.name}
                  </span>
                </div>

                <div className="col-xl-4">
                  <label>کیلومتر اخیر</label>
                  <input
                    type="text"
                    name="currentKM"
                    className="form-control"
                    onChange={handleChange}
                    value={values.currentKM}
                  />
                  <span className="inputErrors">
                    {touched.currentKM && errors.currentKM}
                  </span>
                </div>

                <div className="col-xl-4">
                  <label>کیلومتر بعدی</label>
                  <input
                    type="text"
                    name="nextKM"
                    className="form-control"
                    onChange={handleChange}
                    value={values.nextKM}
                  />
                  <span className="inputErrors">
                    {touched.nextKM && errors.nextKM}
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.add}>
              {(values.changelog?.engineOil ||
                values.changelog?.gearOil ||
                values.changelog?.oilFilter ||
                values.changelog?.cabinFilter ||
                values.changelog?.airFilter ||
                values.changelog?.hydraulicOil ||
                values.changelog?.fuelFilter ||
                values.changelog?.accessories?.length > 0) && (
                <div className="row">
                  <div className={styles.title}>تغییرات</div>

                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <label>روغن موتور</label>

                    <SelectBox
                      name="changelog"
                      options={products.engineOil}
                      isClearable={true}
                      className={styles.select}
                      placeholder="انتخاب کنید..."
                      defaultValue={defaultHandler(values.changelog.engineOil)}
                      onChange={(item) => {
                        values.changelog.engineOil = item;
                        calcTotalPrice();
                      }}
                      isLoading={isLoading}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <label>روغن گیربکس</label>
                    <SelectBox
                      name="changelog"
                      options={products.gearOil}
                      isClearable={true}
                      className={styles.select}
                      placeholder="انتخاب کنید..."
                      defaultValue={defaultHandler(values.changelog.gearOil)}
                      onChange={(item) => {
                        values.changelog.gearOil = item;
                        calcTotalPrice();
                      }}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <label>فیلتر اتاق</label>
                    <SelectBox
                      name="changelog"
                      options={products.cabinFilter}
                      isClearable={true}
                      className={styles.select}
                      placeholder="انتخاب کنید..."
                      defaultValue={defaultHandler(
                        values.changelog.cabinFilter
                      )}
                      onChange={(item) => {
                        values.changelog.cabinFilter = item;
                        calcTotalPrice();
                      }}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <label>فیلتر هوا</label>
                    <SelectBox
                      name="changelog"
                      options={products.airFilter}
                      isClearable={true}
                      className={styles.select}
                      placeholder="انتخاب کنید..."
                      defaultValue={defaultHandler(values.changelog.airFilter)}
                      onChange={(item) => {
                        values.changelog.airFilter = item;
                        calcTotalPrice();
                      }}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <label>روغن فرمان </label>
                    <SelectBox
                      name="changelog"
                      options={products.hydraulicOil}
                      isClearable={true}
                      className={styles.select}
                      placeholder="انتخاب کنید..."
                      defaultValue={defaultHandler(
                        values.changelog.hydraulicOil
                      )}
                      onChange={(item) => {
                        values.changelog.hydraulicOil = item;
                        calcTotalPrice();
                      }}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <label>فیلتر بنزین</label>
                    <SelectBox
                      name="changelog"
                      options={products.fuelFilter}
                      isClearable={true}
                      className={styles.select}
                      placeholder="انتخاب کنید..."
                      defaultValue={defaultHandler(values.changelog.fuelFilter)}
                      onChange={(item) => {
                        values.changelog.fuelFilter = item;
                        calcTotalPrice();
                      }}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <label>فیلتر روغن</label>
                    <SelectBox
                      name="changelog"
                      options={products.oilFilter}
                      isClearable={true}
                      className={styles.select}
                      placeholder="انتخاب کنید..."
                      defaultValue={defaultHandler(values.changelog.oilFilter)}
                      onChange={(item) => {
                        values.changelog.oilFilter = item;
                        calcTotalPrice();
                      }}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <label>محصولات جانبی</label>
                    <SelectBox
                      name="changelog"
                      options={products.accessories}
                      isClearable={true}
                      className={styles.select}
                      placeholder="انتخاب کنید..."
                      isMulti={true}
                      defaultValue={defaultHandler(
                        values.changelog.accessories,
                        true
                      )}
                      onChange={(item) => {
                        values.changelog.accessories = item;
                        calcTotalPrice();
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={styles.add}>
              <div className="row">
                <div className={styles.title}>توضیحات</div>
                <div className="col-xl-6">
                  <textarea
                    name="description"
                    className="form-control"
                    value={values.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className={styles.add}>
              <div className="row">
                <div className="col-xl-4">
                  <label>هزینه مازاد</label>
                  <input
                    type="text"
                    name="extraCost"
                    className="form-control"
                    onChange={(e) => {
                      setExtraCost(e.target.value);
                      calcTotalPrice(e.target.value);
                    }}
                    value={extraCost}
                  />
                </div>
              </div>
            </div>
            <div className={styles.add}>
              <div className="row">
                <div className="col-xl-4">
                  <div className={styles.total}>قیمت کل</div>
                  <div className={styles.price}>{totalPrice}</div>
                </div>
              </div>
            </div>

            <div className="col-6" className={styles.btn}>
              <button className="btn btn-success" type="submit">
                ثبت
              </button>

              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  handelDeleteModal();
                }}
              >
                حذف
              </button>
            </div>
          </form>
        )}
      </Formik>

      <DeleteModal
        show={showDeleteModal}
        onClose={handelDeleteModal}
        onDelete={() => {
          deleteInvoice();
          handelDeleteModal();
        }}
      />
    </div>
  );
}
