import React, { Fragment, useContext } from "react";
import { Switch, Route, Redirect,HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AuthContext from "../store/auth-context";
import NavBar from "../components/UI/NavBar";
import Header from "../components/UI/Header";
import Users from "./user/Users";
import UserPanel from "./user/UserPanel";
import History from "./user/History";
import InVoice from "./user/InVoice";
import AddInvoice from "./user/AddInvoice";
import Club from "./user/Club";
import Product from "./product/Product";
import AddProduct from "./product/AddProduct";
import EditProduct from "./product/EditProduct";
import Company from "./company/Company";
import Cars from "./company/Cars";
import AddCar from "./company/AddCar";
import EditCar from "./company/EditCar";
import News from "./news/News";
import EditNews from "./news/EditNews";
import AddNews from "./news/AddNews";
import Message from "./message/Message";
import Setting from "./setting/EditSetting";
import Login from "./Login";

const Index = () => {
  const AuthCntx = useContext(AuthContext);
  if (!AuthCntx.isLoggedIn) {
    return (
      <Fragment>
        <ToastContainer rtl />
        <Route path="/" exact>
          <Login />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Fragment>
    );
  } else if (AuthCntx.isLoggedIn) {
    return (
      <HashRouter>
        <ToastContainer rtl />
        <Switch>
          <Route path="/users" exact>
            <Header title="کاربران" />
            <Users />
          </Route>
          <Route path="/users/:_Id" exact>
            <Header title="کاربر" backBtn />
            <UserPanel />
          </Route>
          <Route path="/users/:userId/:carId" exact>
            <Header title="تاریخچه فاکتورها" backBtn />
            <History />
          </Route>
          <Route path="/users/:userId/:carId/invoice/add" exact>
            <Header title="افزودن فاکتور " backBtn />
            <AddInvoice />
          </Route>
          <Route path="/users/:userId/:carId/invoice/:invoiceId">
            <Header title="فاکتور" backBtn />
            <InVoice />
          </Route>
          <Route path="/club">
            <Header title="باشگاه مشتریان" />
            <Club />
          </Route>

          <Route path="/products" exact>
            <Header title="محصولات" />
            <Product />
          </Route>
          <Route path="/products/add">
            <Header title="افزودن محصول" backBtn />
            <AddProduct />
          </Route>
          <Route path="/products/:productId">
            <Header title="ویرایش محصول" backBtn />
            <EditProduct />
          </Route>

          <Route path="/company" exact>
            <Header title="شرکت ها" />
            <Company />
          </Route>
          <Route path="/company/:companyId" exact>
            <Header title="خودرو ها" backBtn />
            <Cars />
          </Route>
          <Route path="/company/:companyId/cars/add" exact>
            <Header title="افزودن خودرو" backBtn />
            <AddCar />
          </Route>
          <Route path="/company/:companyId/cars/:carId">
            <Header title="ویرایش خودرو" backBtn />
            <EditCar />
          </Route>

          <Route path="/news" exact>
            <Header title="اخبار" />
            <News />
          </Route>
          <Route path="/news/add">
            <Header title="افزودن خبر" backBtn />
            <AddNews />
          </Route>
          <Route path="/news/:newsId">
            <Header title="ویرایش خبر" backBtn />
            <EditNews />
          </Route>

          <Route path="/message" exact>
            <Header title="ارسال پیام" />
            <Message />
          </Route>

          <Route path="/setting" exact>
            <Header title="تنظیمات" />
            <Setting />
          </Route>

          <Route path="*">
            <Redirect to="/users" />
          </Route>
        </Switch>
        <NavBar />
      </HashRouter>
    );
  }
};

export default Index;
