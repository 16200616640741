import React, { useEffect, useState } from "react";
import Editor, { buttonList } from "suneditor-react";
import { toast } from "react-toastify";

import styles from "./EditSetting.module.css";
import {
  getConfig as getConfigRequest,
  setConfig as setConfigRequest,
} from "../../api/requests";
import LoadingCM from "../../components/UI/Loading";

function Setting() {
  const [isLoading, setIsLoading] = useState(false);
  const [contactUs, setContactUs] = useState("");
  const [aboutUs, setAboutUs] = useState("");

  const getConfig = async () => {
    setIsLoading(true);
    const res = await getConfigRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setAboutUs(res.data.aboutUs);
    setContactUs(res.data.contactUs);
  };
  useEffect(() => {
    getConfig();
  }, []);

  const handelContactUsSumbit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await setConfigRequest({
      key: "contactUs",
      value: contactUs,
    });
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("عملیات با موفقیت انجام شد", {
      position: "top-center",
      autoClose: 2000,
    });
  };
  const handelAboutUsSumbit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await setConfigRequest({
      key: "aboutUs",
      value: aboutUs,
    });
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("عملیات با موفقیت انجام شد", {
      position: "top-center",
      autoClose: 2000,
    });
  };

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <div className="main-panel">
      <div className={styles.add}>
        <div className="row">
          <div className="col-xl-12">
            <label>تماس با ما</label>
            {contactUs && (
              <Editor
                enableToolbar={true}
                defaultValue={contactUs ? contactUs : ""}
                onChange={(text) => setContactUs(text)}
                setDefaultStyle="font-family: IranSans; height: 40vh;"
                setOptions={{
                  buttonList: buttonList.complex,
                  font: [
                    "IranSans",
                    "IranSansBold",
                    "Arial",
                    "Impact",
                    "Tahoma",
                  ],
                }}
              />
            )}
          </div>
          <div className="col-xl-4" className={styles.btn}>
            <button className="btn btn-success" onClick={handelContactUsSumbit}>
              ثبت
            </button>
          </div>

          <div className="col-xl-12">
            <label>ارتباط با ما</label>
            {aboutUs && (
              <Editor
                enableToolbar={true}
                defaultValue={aboutUs ? aboutUs : ""}
                onChange={(text) => setAboutUs(text)}
                setDefaultStyle="font-family: IranSans; height: 40vh;"
                setOptions={{
                  buttonList: buttonList.complex,
                  font: [
                    "IranSans",
                    "IranSansBold",
                    "Arial",
                    "Impact",
                    "Tahoma",
                  ],
                }}
              />
            )}
          </div>
          <div className="col-xl-4" className={styles.btn}>
            <button className="btn btn-success" onClick={handelAboutUsSumbit}>
              ثبت
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setting;
