import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router";
import { toast } from "react-toastify";
import { MdAdd } from "react-icons/md";

import { getUserHistories } from "../../api/requests";
import CustomLink from "../../components/UI/Link";
import styles from "./History.module.css";
import LoadingCM from "../../components/UI/Loading";
import HistoryCard from "../../components/user/HistoryCard";

function History() {
  const { url } = useRouteMatch();
  const params = useParams();
  const [histories, setHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getHistories = async () => {
    setIsLoading(true);
    const res = await getUserHistories(params.userId, params.carId);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setHistories(res.data);
  };

  useEffect(() => {
    getHistories();
  }, []);

  const content = histories.map((item) => (
    <CustomLink normal key={item._id} to={`${url}/invoice/${item._id}`}>
      <HistoryCard item={item} />
    </CustomLink>
  ));

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <div className="main-panel">
      <div className={`${styles.container} col-5`}>{content}</div>
      <CustomLink normal="true" to={`${url}/invoice/add`} className={styles.fixbtn}>
        <MdAdd size={24} />
      </CustomLink>
    </div>
  );
}

export default History;
