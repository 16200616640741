import React, { useEffect, useState } from "react";
import Editor, { buttonList } from "suneditor-react";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";

import styles from "./EditNews.module.css";
import LoadingCM from "../../components/UI/Loading";
import { editNews, getNewsById, deleteNews } from "../../api/requests";
import { IMG_BASE_URL } from "../../constants/main";
import DeleteModal from "../../components/UI/DeleteModal";

function AddNews() {
  const history = useHistory();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState({});
  const [changedImage, setChangedImage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handelDeleteModal = () => {
    setShowDeleteModal((state) => !state);
  };

  const handelSubmit = async () => {
    if (!title || !content) {
      return toast.error("لطفا همه ی فیلد ها را پر کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }

    setIsLoading(true);
    let values = { image, title, content };
    const res = await editNews(params.newsId, values);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("اخبار با موفقیت ویرایش شد", {
      position: "top-center",
      autoClose: 1500,
    });

    setTimeout(() => history.goBack(), 1500);
  };

  const getNews = async () => {
    setIsLoading(true);
    const res = await getNewsById(params.newsId);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }

    let news = res.data;

    setTitle(news.title);
    setContent(news.content);
    setImage(news.imageUrl);
  };

  const handelDelete = async () => {
    setIsLoading(true);
    const res = await deleteNews(params.newsId);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("اخبار با موفقیت حذف شد", {
      position: "top-center",
      autoClose: 1500,
    });

    setTimeout(() => history.goBack(), 1500);
  };
  useEffect(() => {
    getNews();
  }, []);

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <div className="main-panel">
      <div className={styles.add}>
        <div className="row">
          <div className="col-xl-6">
            {
              <label htmlFor="image" className={styles.excel}>
                {image ? (
                  <>
                    {changedImage && (
                      <img
                        src={`${URL.createObjectURL(image)}`}
                        className={styles.image}
                      />
                    )}
                    {!changedImage && (
                      <img
                        src={`${IMG_BASE_URL}/${image}`}
                        className={styles.image}
                      />
                    )}
                  </>
                ) : (
                  "محل آپلود عکس"
                )}
              </label>
            }
            <input
              id="image"
              type="file"
              style={{ visibility: "hidden" }}
              className="form-control"
              onChange={(e) => {
                if (e.target.files[0].size > 500 * 1000) {
                  return toast.error("حجم عکس باید کمتر از 500 کیلوبایت باشد", {
                    position: "top-center",
                    autoClose: 2000,
                  });
                }
                setChangedImage(true);
                setImage(e.target.files[0]);
              }}
            />
          </div>

          <br />

          <div className="col-xl-6">
            <label>عنوان</label>
            <input
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="col-xl-12">
            <label>متن</label>
            {content && (
              <Editor
                enableToolbar={true}
                onChange={(text) => setContent(text)}
                defaultValue={content}
                setDefaultStyle="font-family: IranSans; height: 40vh;"
                setOptions={{
                  buttonList: buttonList.complex,
                  font: [
                    "IranSans",
                    "IranSansBold",
                    "Arial",
                    "Impact",
                    "Tahoma",
                  ],
                }}
              />
            )}
            {!content && (
              <Editor
                enableToolbar={true}
                onChange={(text) => setContent(text)}
                setDefaultStyle="font-family: IranSans; height: 40vh;"
                setOptions={{
                  buttonList: buttonList.complex,
                  font: [
                    "IranSans",
                    "IranSansBold",
                    "Arial",
                    "Impact",
                    "Tahoma",
                  ],
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className="col-xl-4" className={styles.btn}>
        <button className="btn btn-success" onClick={handelSubmit}>
          ویرایش
        </button>

        <button
          className="btn btn-danger col-6"
          style={{ marginRight: 5 }}
          onClick={(e) => {
            e.preventDefault();
            handelDeleteModal();
          }}
        >
          حذف
        </button>
      </div>

      <DeleteModal
        show={showDeleteModal}
        onClose={handelDeleteModal}
        onDelete={() => {
          handelDelete();
          handelDeleteModal();
        }}
      />
    </div>
  );
}

export default AddNews;
