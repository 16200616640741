import React from "react";

import styles from "./ProfileCarCard.module.css";
import { IMG_BASE_URL } from "../../constants/main";

function ProfileCard(props) {
  return (
    <div className={styles.row}>
      <div className={styles.item}>
        <img src={`${IMG_BASE_URL}/${props.item.company?.imageUrl}`} />
        <span>{props.item.name}</span>
      </div>
    </div>
  );
}

export default ProfileCard;
