import React, { useState } from "react";
import Editor, { buttonList } from "suneditor-react";
import { useHistory } from "react-router";
import { MdUpload } from "react-icons/md";
import { toast } from "react-toastify";

import styles from "./AddNews.module.css";
import LoadingCM from "../../components/UI/Loading";
import { addNews } from "../../api/requests";

function AddNews() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState({});

  const handelSubmit = async () => {
    if (!image.name || !title || !content) {
      return toast.error("لطفا همه ی فیلد ها را پر کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    setIsLoading(true);
    let values = { image, title, content };
    const res = await addNews(values);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("خبر با موفقیت افزوده شد", {
      position: "top-center",
      autoClose: 1500,
    });

    setTimeout(() => history.goBack(), 1500);
  };

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <div className="main-panel">
      <div className={styles.add}>
        <div className="row">
          <div className="col-xl-6">
            {
              <label htmlFor="image" className={styles.excel}>
                <MdUpload size={22} />{" "}
                {image.name ? (
                  <img
                    src={`${URL.createObjectURL(image)}`}
                    className={styles.image}
                  />
                ) : (
                  "محل آپلود عکس"
                )}
              </label>
            }
            <input
              id="image"
              type="file"
              style={{ visibility: "hidden" }}
              className="form-control"
              onChange={(e) => {
                if (e.target.files[0].size > 500 * 1000) {
                  return toast.error("حجم عکس باید کمتر از 500 کیلوبایت باشد", {
                    position: "top-center",
                    autoClose: 2000,
                  });
                }
                setImage(e.target.files[0]);
              }}
            />
          </div>

          <br />

          <div className="col-xl-6">
            <label>عنوان</label>
            <input
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="col-xl-12">
            <label>متن</label>
            <Editor
              enableToolbar={true}
              onChange={(text) => setContent(text)}
              setDefaultStyle="font-family: IranSans; height: 40vh;"
              setOptions={{
                buttonList: buttonList.complex,
                font: ["IranSans", "IranSansBold", "Arial", "Impact", "Tahoma"],
              }}
            />
          </div>
        </div>
      </div>

      <div className="col-xl-4" className={styles.btn}>
        <button className="btn btn-success" onClick={handelSubmit}>ثبت</button>
      </div>
    </div>
  );
}

export default AddNews;
