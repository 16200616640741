import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { DatePicker } from "jalali-react-datepicker";
import { toast } from "react-toastify";
import { Formik } from "formik";
import SelectBox from "react-select";

import styles from "./InVoice.module.css";
import LoadingCM from "../../components/UI/Loading";
import { getAllProductByGroup, addInvoice } from "../../api/requests";

export default function InVoice() {
  const formRef = useRef();
  const location = useHistory();
  const params = useParams();
  const [products, setProducts] = useState({
    engineOil: [],
    oilFilter: [],
    gearOil: [],
    cabinFilter: [],
    airFilter: [],
    fuelFilter: [],
    hydraulicOil: [],
    accessories: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [extraCost, setExtraCost] = useState(0);

  const calcTotalPrice = (extraCostValue = "0") => {
    let changelog = formRef.current.values.changelog;
    var sum = 0;

    for (let key in changelog) {
      if (key == "accessories") {
        if (changelog[key].length) {
          for (let index in changelog[key]) {
            sum += eval(changelog[key][index].price);
          }
        }
      } else if (changelog[key]) {
        sum += eval(changelog[key].price);
      }
    }
    sum += +extraCostValue;
    setTotalPrice(sum);
  };

  const getProductsByCategory = async () => {
    setIsLoading(true);
    const result = await getAllProductByGroup();

    if (!result.ok) {
      setIsLoading(false);
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    let productObject = {};
    for (let value of result.data) {
      productObject[value.category] = value.products.map((items) => ({
        label: items.name,
        value: items._id,
        price: items.price,
      }));
    }

    setIsLoading(false);
    setProducts(productObject);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    let changelog = values.changelog;
    values.extraCost = extraCost;
    delete values.totalPrice;

    for (let key in changelog) {
      if (key == "accessories") {
        if (changelog[key].length) {
          for (let index in values.changelog[key]) {
            values.changelog[key][index] = changelog[key][index].value;
          }
        } else {
          changelog[key] = null;
        }
      } else if (changelog[key]) {
        values.changelog[key] = changelog[key].value;
      }
    }

    if (values.serviceDate instanceof Date) {
      values.serviceDate = new Date(values.serviceDate).valueOf();
    }

    const res = await addInvoice(values);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }

    toast.success("افزودن موفقیت آمیز بود", {
      position: "top-center",
      autoClose: 2000,
    });
    setTimeout(() => location.goBack(), 2000);
  };

  useEffect(() => {
    getProductsByCategory();
  }, []);

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <div className="main-panel">
      <Formik
        innerRef={formRef}
        initialValues={{
          user: params.userId ? params.userId : "",
          car: params.carId ? params.carId : "",
          serviceDate: new Date().valueOf(),
          currentKM: "",
          nextKM: "",
          description: "",
          extraCost: 0,
          totalPrice: "",
          changelog: {
            engineOil: null,
            oilFilter: null,
            gearOil: null,
            cabinFilter: null,
            airFilter: null,
            fuelFilter: null,
            hydraulicOil: null,
            accessories: [],
          },
        }}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(values, setSubmitting)
        }
      >
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.add}>
              <div className="row">
                <div className={styles.title}>مشخصات</div>

                <div className="col-xl-4">
                  <div className={styles.inputdate}>
                    <label>تاریخ</label>

                    <DatePicker
                      onClickSubmitButton={(e) =>
                        (values.serviceDate = e.value._d)
                      }
                      className="form-control"
                      timePicker={false}
                      name="serviceDate"
                      value={values.serviceDate}
                    />
                  </div>
                </div>

                <div className="col-xl-4">
                  <label>کیلومتر اخیر</label>
                  <input
                    type="text"
                    name="currentKM"
                    className="form-control"
                    onChange={handleChange}
                    value={values.currentKM}
                  />
                </div>

                <div className="col-xl-4">
                  <label>کیلومتر بعدی</label>
                  <input
                    type="text"
                    name="nextKM"
                    className="form-control"
                    onChange={handleChange}
                    value={values.nextKM}
                  />
                </div>
              </div>
            </div>

            <div className={styles.add}>
              <div className="row">
                <div className={styles.title}>تغییرات</div>

                <div className="col-xl-3 col-lg-4 col-md-6">
                  <label>روغن موتور</label>

                  <SelectBox
                    name="changelog"
                    options={products.engineOil}
                    isClearable={true}
                    className={styles.select}
                    placeholder="انتخاب کنید..."
                    onChange={(item) => {
                      values.changelog.engineOil = item;
                      calcTotalPrice();
                    }}
                    isLoading={isLoading}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6">
                  <label>روغن گیربکس</label>
                  <SelectBox
                    name="changelog"
                    options={products.gearOil}
                    isClearable={true}
                    className={styles.select}
                    placeholder="انتخاب کنید..."
                    onChange={(item) => {
                      values.changelog.gearOil = item;
                      calcTotalPrice();
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6">
                  <label>فیلتر اتاق</label>
                  <SelectBox
                    name="changelog"
                    options={products.cabinFilter}
                    isClearable={true}
                    className={styles.select}
                    placeholder="انتخاب کنید..."
                    onChange={(item) => {
                      values.changelog.cabinFilter = item;
                      calcTotalPrice();
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6">
                  <label>فیلتر هوا</label>
                  <SelectBox
                    name="changelog"
                    options={products.airFilter}
                    isClearable={true}
                    className={styles.select}
                    placeholder="انتخاب کنید..."
                    onChange={(item) => {
                      values.changelog.airFilter = item;
                      calcTotalPrice();
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6">
                  <label>روغن فرمان </label>
                  <SelectBox
                    name="changelog"
                    options={products.hydraulicOil}
                    isClearable={true}
                    className={styles.select}
                    placeholder="انتخاب کنید..."
                    onChange={(item) => {
                      values.changelog.hydraulicOil = item;
                      calcTotalPrice();
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6">
                  <label>فیلتر بنزین</label>
                  <SelectBox
                    name="changelog"
                    options={products.fuelFilter}
                    isClearable={true}
                    className={styles.select}
                    placeholder="انتخاب کنید..."
                    onChange={(item) => {
                      values.changelog.fuelFilter = item;
                      calcTotalPrice();
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6">
                  <label>فیلتر روغن</label>
                  <SelectBox
                    name="changelog"
                    options={products.oilFilter}
                    isClearable={true}
                    className={styles.select}
                    placeholder="انتخاب کنید..."
                    onChange={(item) => {
                      values.changelog.oilFilter = item;
                      calcTotalPrice();
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6">
                  <label>محصولات جانبی</label>
                  <SelectBox
                    name="changelog"
                    options={products.accessories}
                    isClearable={true}
                    className={styles.select}
                    placeholder="انتخاب کنید..."
                    isMulti={true}
                    onChange={(item) => {
                      values.changelog.accessories = item;
                      calcTotalPrice();
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={styles.add}>
              <div className="row">
                <div className={styles.title}>توضیحات</div>
                <div className="col-xl-6">
                  <textarea
                    name="description"
                    className="form-control"
                    value={values.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className={styles.add}>
              <div className="row">
                <div className="col-xl-4">
                  <label>هزینه مازاد</label>
                  <input
                    type="text"
                    name="extraCost"
                    className="form-control"
                    onChange={(e) => {
                      setExtraCost(e.target.value);
                      calcTotalPrice(e.target.value);
                    }}
                    value={extraCost}
                  />
                </div>
              </div>
            </div>
            <div className={styles.add}>
              <div className="row">
                <div className="col-xl-4">
                  <div className={styles.total}>قیمت کل</div>
                  <div className={styles.price}>{totalPrice}</div>
                </div>
              </div>
            </div>

            <div className="col-6" className={styles.btn}>
              <button className="btn btn-success" type="submit">
                اضافه
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
