import React from "react";

import styles from "./UserCard.module.css";

export default function UserCard(props) {
  return (
    <div className="row">
      <div className="col-5 col-xl-1 col-lg-1 col-md-2 col-sm-2">
        <div className={styles.item}>
          <div>
            <span>ردیف</span>
            <span>{props.index + 1}</span>
          </div>
        </div>
      </div>

      <div className="col-7 col-xl-3 col-lg-3 col-md-3 col-sm-3">
        <div className={styles.item}>
          <div>
            <span>نام و نام خانوادگی</span>
            <span>{props.item.name}</span>
          </div>
        </div>
      </div>

      <div className="col-5 col-xl-3 col-lg-3 col-md-3 col-sm-3 ">
        <div className={styles.item}>
          <div>
            <span>شماره اشتراک</span>
            <span>{props.item.cid}</span>
          </div>
        </div>
      </div>

      <div className="col-7 col-xl-3 col-lg-3 col-md-3 col-sm-3">
        <div className={styles.item}>
          <div>
            <span>شماره موبایل</span>
            <span>{props.item.phoneNumber}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
