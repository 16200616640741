import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import Select from "react-select";
import LoadingCM from "../../components/UI/Loading";
import {
  getCompanies as getCompaniesRequest,
  sendSms,
} from "../../api/requests";

import styles from "./Message.module.css";

function Message() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [content, setContent] = useState("");

  const getCompanies = async () => {
    setIsLoading(true);
    const res = await getCompaniesRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }

    let companiesObj = res.data.map((items) => ({
      label: items.name,
      value: items._id,
    }));
    setCompanies(companiesObj);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const handelSubmit = async () => {
    if (content.trim() == "") {
      return toast.error("متن نباید حالی باشد", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    let value = { message: content, company: selectedCompany };
    setIsLoading(true);
    const res = await sendSms(value);
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    toast.success("پیام با موفقیت ارسال شد", {
      position: "top-center",
      autoClose: 2000,
    });
    history.goBack();
  };

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <div className="main-panel">
      <div className={styles.container}>
        <label>شرکت ها</label>
        <Select
          name="changelog"
          options={companies}
          className={styles.select}
          placeholder="انتخاب کنید..."
          defaultValue={{
            value: {
              _id: "all",
            },
            label: "همه",
          }}
          onChange={(item) => {
            setSelectedCompany(item.value);
          }}
        />

        <p>متن پیامک</p>
        <textarea
          className="form-control"
          onChange={(e) => setContent(e.target.value)}
        ></textarea>

        <div className={styles.btn} onClick={handelSubmit}>
          <button className="btn btn-success">ارسال</button>
        </div>
      </div>
    </div>
  );
}

export default Message;
