import React, { Fragment, useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import { toast } from "react-toastify";

import CustomLink from "../../components/UI/Link";
import NotFound from "../../components/UI/NotFound";
import UserCard from "../../components/user/UserCard";
import LoadingCM from "../../components/UI/Loading";
import { getAllUser as getUserRequest } from "../../api/requests";
import Search from "../../components/UI/Search";
import styles from "./Users.module.css";

function Users() {
  const { url } = useRouteMatch();
  const [users, setuser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredUser, setFiltereduser] = useState([]);

  const getUser = async () => {
    setIsLoading(true);
    const res = await getUserRequest();
    setIsLoading(false);

    if (!res.ok) {
      return toast.error("خطایی رخ داده است مجددا تلاش کنید", {
        position: "top-center",
      });
    }
    setuser(res.data);
    setFiltereduser(res.data);
  };

  useEffect(() => {
    getUser();
  }, []);

  const handelSearchInput = (value) => {
    if (value.trim() === "") return setFiltereduser(users);

    const filteredItems = users.filter(
      (item) => item.name.match(value) || item.cid.match(value) ||item.phoneNumber.match(value)
    );
    setFiltereduser(filteredItems);
  };

  const content = filteredUser.map((item, index) => (
    <CustomLink
      className={styles.customlink}
      normal="true"
      key={item._id}
      to={`${url}/${item._id}`}
    >
      <div className={styles.row}>
        {" "}
        {<UserCard item={item} index={index} />}
      </div>
    </CustomLink>
  ));

  if (isLoading) return <LoadingCM type="bars" />;

  return (
    <Fragment>
      <Search onInput={(val) => handelSearchInput(val)} />
      {filteredUser.length < 1 ? (
        <NotFound title="کاربری یافت نشد" />
      ) : (
        <div className="main-panel">{content}</div>
      )}
    </Fragment>
  );
}

export default Users;
