import React, { Fragment, useContext, useState } from "react";
import { AiFillSetting } from "react-icons/ai";
import { MdMessage, MdGroups, MdExitToApp } from "react-icons/md";
import { IoCarSport, IoNewspaperSharp } from "react-icons/io5";
import { RiOilFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";

import CustomLink from "./Link.jsx";
import img from "../../assets/img/canvas.png";
import logo from "../../assets/img/logo-512.png";
import styles from "./NavBar.module.css";
import AuthContext from "../../store/auth-context.js";

function NavBar() {
  const AuthCTX = useContext(AuthContext);
  const [showNav, setShowNav] = useState(false);
  
  const handleNav = () => setShowNav((state) => !state);
  const logoutHandler = () => AuthCTX.logout();

  return (
    <Fragment>
      <div
        className={`
          ${styles.body} ${showNav ? styles.showBackBtn : styles.hideBackBtn}`}
        id="backmenu"
        onClick={handleNav}
      ></div>
      <img src={img} onClick={handleNav} className={styles.btnshow} alt="" />
      <div
        className={`
          ${styles.navbar} ${showNav ? styles.showNav : styles.hideNav}`}
      >
        <img src={logo} alt="" />
        <CustomLink
          normal="true"
          to="/users"
          className={styles.links}
          onClick={handleNav}
        >
          <span>{<FaUser size={20} />} کاربران</span>
        </CustomLink>

        <CustomLink
          normal="true"
          to="/products"
          className={styles.links}
          onClick={handleNav}
        >
          <span>{<RiOilFill size={20} />} محصولات</span>
        </CustomLink>

        <CustomLink
          normal="true"
          to="/company"
          className={styles.links}
          onClick={handleNav}
        >
          <span>{<IoCarSport size={20} />} شرکت ها</span>
        </CustomLink>

        <CustomLink
          normal="true"
          to="/news"
          className={styles.links}
          onClick={handleNav}
        >
          <span>{<IoNewspaperSharp size={20} />} اخبار</span>
        </CustomLink>

        <CustomLink
          normal="true"
          to="/club"
          className={styles.links}
          onClick={handleNav}
        >
          <span>{<MdGroups size={24} />} باشگاه مشتریان</span>
        </CustomLink>

        <CustomLink
          normal="true"
          to="/message"
          className={styles.links}
          onClick={handleNav}
        >
          <span>{<MdMessage size={20} />} ارسال پیام</span>
        </CustomLink>

        <CustomLink
          normal="true"
          to="/setting"
          className={styles.links}
          onClick={handleNav}
        >
          <span> {<AiFillSetting size={20} />} تنظیمات</span>
        </CustomLink>

        <div className={styles.links} onClick={logoutHandler}>
          <MdExitToApp size={20} />
          <span> خروج </span>
        </div>
      </div>
    </Fragment>
  );
}

export default NavBar;
