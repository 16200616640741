import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";

import spliter from "../helper/spliter";
import styles from "./ClubCard.module.css";

export default function ClubCard(props) {
  return (
    <div className="row">
      <div className="col-2 col-xl-1 col-lg-1 col-md-1 col-sm-2">
        <div className={styles.item}>
          <div>
            <span>ردیف</span>
            <span>{props.index + 1}</span>
          </div>
        </div>
      </div>

      <div className="col-5 col-xl-3 col-lg-3 col-md-3">
        <div className={styles.item}>
          <div>
            <span>نام و نام خانوادگی</span>
            <span>{props.item?.userInfo[0]?.name}</span>
          </div>
        </div>
      </div>

      <div className="col-5 col-xl-3 col-lg-3 col-md-3">
        <div className={styles.item}>
          <div>
            <span>شماره موبایل</span>
            <span>{props.item?.userInfo[0]?.phoneNumber}</span>
          </div>
        </div>
      </div>
      <div className="col-6 col-xl-3 col-lg-3 col-md-3">
        <div className={styles.item}>
          <div>
            <span> خرید کل</span>
            <span>{spliter(props.item?.totalBuyAmount.toString())} تومان</span>
          </div>
        </div>
      </div>
      <div className="col-6 col-xl-2 col-lg-2 col-md-2">
        <div className={styles.item}>
          <div>
            <span>عضو باشگاه</span>
            <span
              className={styles.memberIcon}
              onClick={() =>
                props.onSetMember(
                  props.item?.userInfo[0]?._id,
                  props.item?.userInfo[0]?.clubMember
                )
              }
            >
              {props.item?.userInfo[0]?.clubMember == true ? (
                <FaCheck fill="green" />
              ) : (
                <FaTimes fill="red" />
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
