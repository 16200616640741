import React from "react";
import LoadingCM from "react-loading";
import styles from "./Loading.module.css";

export default function Loading(props) {
  return (
    <div className={`${styles.loading} ${props.isModal ? styles.isModal : ""}`}>
      <LoadingCM type={props.type} color={props.color} />
    </div>
  );
}
