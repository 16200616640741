
//for bottom tab-bar changing route
export const ROUTE_NAMES = { news: "اخبار", more: "بیشتر", home: "سرویس ها" }

//token
// export const PUBLIC_TOKEN =
//     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbGwiOnRydWUsInNlY3JldCI6Ik12a0IlMjNMOGFYY3VIc24iLCJpYXQiOjE2MDcxNzg1NTF9.anMJvzKM9boOs1yx5vQcrM5aF7YZT9ITbiJdl1-tGPQ'
// export const TOKEN_SECRET="^Zq8M3d_mE-sG&WYNA^";

export const PUBLIC_TOKEN =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZmVjY2M5MjE0YmVjZjE4ZGJmMzdjZGUiLCJlbWFpbCI6ImFsaUBnbWFpbC5jb20iLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE2MDkzNTUyNDJ9.O2xqQ_zriIY2PQpBWLn_4I11IMusBy1WhYlnymuU1oc'
export const TOKEN_SECRET = "^Zq8M3d_mE-sG&WYNA^";

export const IMG_BASE_URL = 'https://api.autoservicefarshid.ir';

export const EXCEL_LOG = IMG_BASE_URL + '/uploads/excel.log';

export const CATEGORIES = {
    engineOil: "روغن موتور",
    gearOil: "روغن گیربکس",
    airFilter: "فیلتر هوا",
    cabinFilter: "فیلتر اتاق",
    fuelFilter: "فیلتر بنزین",
    oilFilter: "فیلتر روغن",
    hydraulicOil: "روغن فرمان",
    accessories: "محصولات جانبی",

}